$ = jQuery.noConflict();
consent_types = ['marketing_cookies'];

function telController() {
  if (gdprConsentCheck(consent_types)) {

    console.log('gdpr passed for telcontroller');

    var $telSpan = $('.telephone-span.mhMobile').first();

    if ($telSpan.length == 0) {
      console.log('not here');
      return false;
    }

    var $a = $telSpan.find('a:not(.from-cookie)');

    if ($a.length > 0) {

      // temporary solution for chiswick
      if($('body').hasClass('postid-346')) {
        var tel = {
          href: 'tel:01293837892',
          text: '01293 837 892'
        };
      } else{
        var tel = {
          href: $a.attr('href'),
          text: $a.text()
        };
      }
      Cookies.set('tel', tel);
      // console.log('set cookie');
      if ($('body.retain-telephone').length == 0) {
        $('.telephone-wrap').addClass('telephone-wrap--show'); // show the tel number
      }
      return false;
    }

    if ($a.length == 0) {
      // keep on looping if mediahawk a tag is not loaded
      setTimeout(function () {
        telController();
      }, 100);
    }

  }

  else {
    console.log('gdpr declined or not accepted, user cannot utilise mediahawk');
    $('.telephone-wrap').addClass('telephone-wrap--show');
    return false;
  }

}

telController();

function updateMediaHawkNumber(site) {
  if (gdprConsentCheck(consent_types)) {
    console.log('updateMediaHawkNumber for ' + site);
    var sitesObj = JSON.parse(siteData);
    $.each(sitesObj.sites, function (index, value) {
      if (value.name == site) {
        console.log('mediahawk_number', value.mediahawk_number);
        $('.mhMobile').attr('class', 'telephone-span mhMobile ' + value.mediahawk_number);
        $('.telephone-span.mhMobile a').addClass('is-old');
        var mhSrc = '//www.dynamicnumbers.mediahawk.co.uk/mhct.min.js';
        $('script[src="' + mhSrc + '"]').remove();
        !function(){var c=document.createElement("script"); c.type="text/javascript",c.async=!0,c.src="//www.dynamicnumbers.mediahawk.co.uk/mhct.min.js"; var i=document.getElementsByTagName("script")[0];
        i.parentNode.insertBefore(c,i)}();
        telController();
      }
    });
  }
  else {
    console.log('gdpr declined or not accepted, user cannot utilise mediahawk');
    return false;
  }
}
